.particles-container{
    width: 100dvw;
    height: 100dvh; 
    position: absolute;
    z-index: -1 !important;
    top: 0;
    left: 0;
    overflow: hidden !important;
}

#tsparticles{
    width: 100%;
    height: 100%;

}