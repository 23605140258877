.dialog-parent{
    background-color:#202020;
    width: 100%!important;
    height: fit-content !important;
    color: white;
    position: relative;

}

.dialog-sub-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 0; 
}

@media(max-width:800px){
    .dialog-sub-parent{
      
        padding:0 0 3rem 0;
    }
    
}

.detail-paragraph{
    width: 80%;
    text-align: justify;
    color: #8c8c8c;
    font-size: large;
}

.close-icon{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
}

.dialog-top{
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 10;
    background-color:#181717;

    
}