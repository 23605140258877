.blog-card{
    max-width: 30%;
    min-width: 300px;
    width: 30%;
    height: 300px;
    border: 1px solid snow;
    margin:3% 0 0 0;
    cursor: pointer;
}
.blog-card .img{
    width: 100%;
    height: 250px;
    background-position: center;
    background-size: cover;
     
}
.blog-card p{
   padding:0 1rem;
     
}

.blog-titles{
    display: flex;
    flex-direction: column; 
    padding: .5rem;
}
.blog-titles .authors{ 
    font-size: .7rem;
}
.blog-titles p{ 
    margin: 0;padding: 0;

}