
.trusted-by {
    color: #fff;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;

  }

  @media (max-width:900px) {
    .trusted-by { 
      width: 100dvw; 
  
    }
    .trusted-by .text{
      padding-left: 10%;
    }
  }
  .trusted-by .text{
    /* padding-left: 10%; */
    font-size: 1.3rem; 
  }
  .carousel {
    display: flex;
    width: 100%;
    overflow: hidden;
  }
  .carousel-child {
    display: flex;
    width: fit-content;
    gap: 5rem;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-child img {
    /* width: 177.925px; */
    height: 109.051px;
    flex-shrink: 0;
  }
  
  @keyframes carousel-slider {
    from {
      transform: translateX(0);
    }
  
    to {
      transform: translateX(-100%);
    }
  }
  
  .carousel-child.one {
    padding: 3rem;
  
    animation: carousel-slider 25s infinite linear;
  }
  .carousel-child.two {
    padding: 3rem;
  
    animation: carousel-slider 25s infinite linear;
  }
  .carousel-child.three {
    padding: 3rem;
  
    animation: carousel-slider 25s infinite linear;
  }