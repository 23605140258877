.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100dvh - 80px);
  /* overflow: hidden; */
  position: relative;
}

.hero .text {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.hero-img {
  height: calc(100% - 0px);
  opacity: 0.5;
  padding-right: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-shadow {
  background: radial-gradient(
    circle 30rem at 50% 50%,
    rgba(0, 0, 0, 0),
    rgb(0, 0, 0)
  );
  width: 100%;
  height: 100%;
  position: absolute;
}
.hero-img img {
  height: 100%;
  /* box-shadow: 0px 0px 60 yellow; */
  /* filter: drop-shadow(0px 0px 80px rgb(0, 0, 0))drop-shadow(0px 0px 80px rgb(0, 0, 0)) drop-shadow(0px 0px 95px yellow) drop-shadow(0px 0px 100px red); */
  /* animation: lionimate 6s infinite; */
}
/* @keyframes lionimate {
  0%{
    filter: drop-shadow(0px 0px 60px green) drop-shadow(0px 0px 80px yellow) drop-shadow(-40px 0px 100px red);
  }
  20%{
    filter: drop-shadow(-50px 0px 80px green) drop-shadow(0px 40px 100px yellow) drop-shadow(0px 10px 60px red);
  }
  50%{
    filter: drop-shadow(0px 0px 100px green) drop-shadow(0px 20px 60px yellow) drop-shadow(0px 0px 80px red);
  }
  70%{
    filter: drop-shadow(50px 0px 80px green) drop-shadow(0px 50px 100px yellow) drop-shadow(0px 40px 70px red);
  } 
} */
.we-are-anbessa {
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(
    --yeloworange,
    linear-gradient(90deg, #ffa800 44.06%, #ff2f01 99.58%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* line-height: 0.5rem; */
  margin: 0;
}

.your-one-stop {
  font-family: League Gothic;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  line-height: 80%;
}

.solution {
  margin: 0;
  font-family: Poppins;
  font-size: 6rem;
  font-weight: bolder;
  line-height: 110%;
  background: linear-gradient(92deg, #00b031 -6.39%, #fcb602 64.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-paragraph {
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
@media (max-width: 900px) {
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    overflow: hidden;
    position: relative;
    padding: 3.5rem 0;
  }
  .hero .text {
    width: 100% !important;
    height: 100% !important;
    justify-content: center;
    text-align: justify;
    position: relative;
    /* top: 100px; */
    /* bottom: 1.5rem; */
    /* border: 1px solid red; */
    padding: 0 1.5rem;
  }
  .hero-img {
    position: absolute;
    z-index: -1;
    opacity: 0.2 !important;
    display: flex;
    justify-content: center !important;
    width: 100%;
    height: 100%;
    left: 0;
    /* top:80px; */
    /* border: 1px solid red; */
    /* top: 0; */
  }

  .hero-img img {
    height: 100%;
  }

  .sub-body {
    padding: 80px 1rem 1rem 1rem;
  }

  .we-are-anbessa {
    font-size: 2rem;
  }

  .your-one-stop {
    font-size: 2rem;
  }

  .solution {
    font-size: 3.5rem;
  }

  .hero-paragraph {
    font-size: 1rem;
  }
}

.flash {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 5;
  transition: 0.6s;
  left: -12.5%;
  right: 0;
}
#flashlight {
  --Xpos: 50vw;
  --Ypos: 50vh;
  transition: 0.6s;
}
#flashlight:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background: radial-gradient(
    circle 30rem at var(--Xpos) var(--Ypos),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.852)
  );
  transition: 0.6s;
}

@media (max-width: 900px) {
  .flash {
    display: none !important;
  }
}

.--transparent {
  opacity: 0;
  transition: 0.6s;
}
