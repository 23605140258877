.preloader{
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 11;
    background-color: black;
}

.logo-preloader{
    width: 100px;
    height: 100px; 
    background-position: center;
    background-size: contain;

}