

.--portifoliomotion{
    animation: portifoliomotion 7s infinite linear alternate;
}

.--portifoliomotion-reverse{
    animation: portifoliomotion-reverse 7s infinite linear alternate;


}
@keyframes portifoliomotion{
    0% {
        transform: translateY(0%);
    }
    50%{
        transform: translateY(3%);

    }
    100%{
        transform: translateY(0%);
    }
}
@keyframes portifoliomotion-reverse{
    0% {
        transform: translateY(0%);
    }
    50%{
        transform: translateY(-3%);

    }
    100%{
        transform: translateY(0%);
    }
}


@media (max-width:900px){
    .--portifoliomotion{
        animation: none;
    }
    .--portifoliomotion-reverse{
        animation: none;
    }
}


