.clients-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 30%;
  border: 1px solid white;
  border-top: 0px;
  transition: .6s;

}
.clients-card:hover {
    transition: .6s;
  border: 1px solid transparent;
}
@media (max-width: 900px) {
  .clients-card {
    width: 90%;
  }
}
.clients-card .image {
  background-position: center;
  background-size: cover;
  /* height: 30rem; */
  width: calc(100% + 2px);
  border-radius: 0.5rem;
  filter: saturate(0);

  transition: 0.6s;

  overflow: hidden;
  display: flex;
  justify-content: start;
  align-items: stretch; 
  position: relative;
  bottom: 8px;
}
.clients-card .image img {
  /* height: auto; */
  /* height: 100%; */

  width: 100%;
}
.clients-card:hover  .image{
  filter: saturate(1);
  transition: 0.6s;
}

.client-name {
  font-size: 1.5rem;
  align-self: flex-start;
  position: relative;
  bottom: 4px;
  left: 20px;
}

@media (max-width: 700px) {
  .clients-card .image {
    filter: saturate(1);
    transition: 0.6s;
  }
}
