.underline {
  background: linear-gradient(
    -270deg,
    rgba(255, 174, 17, 0) 19.29%,
    rgba(206, 107, 10, 0.69) 119.6%
  );
  width: 25%;
  height: 2px; 
}
.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  transition: 0.2s; 
  background-color: #202020ad;

  position: relative;
}
@keyframes hoveranimation {
  0% {
    transform: translate(0%, 0%);
  }
  10% {
    transform: translate(10%, 2%);
  }
  20% {
    transform: translate(5%, 10%);
  }
  50% {
    transform: translate(-9%, 6%);
  }
  70% {
    transform: translate(10%, -8%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.service-bg {
  background: transparent;
  padding: 1px;
  width: 30%;
  transition: 1s;
  height: fit-content;
}
.service-bg:hover {
  background: linear-gradient(90deg, #ffa800 44.06%, #ff2f01 99.58%);
  transform: translateY(-5%);
  transition: 0.4s;
}
.service-bg:hover {
  transition: 0.4s;
  box-shadow: 0 5px 15px -2px #ffa800;
  /* animation: hoveranimation  5s linear infinite; */
}
.service-bg:hover .service-card {
  background: #202020  !important;
  transition: 0s !important;
}

@media (max-width: 900px) {
  .service-bg {
    width: 90%;
  }
}

.title {
  color: #fff;
  font-family: Poppins; 
  font-size: 33.051px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.service-card ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8c8c;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  left: -1rem;
  list-style: none;
}

.service-card .more {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1.2rem;
}
.service-card .more p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}

.service-card .image {
  margin: 1.5rem;
}
/* .service-card img {
  padding: 1.5rem;
} */

.blur{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  filter: blur(8px);


  /* background-color: blue; */
}


.service-bg{
  cursor: pointer;
}
