 
  @import url('https://fonts.googleapis.com/css2?family=League+Gothic&family=Poppins:wght@400;600&display=swap');
 
body {
  margin: 0;
  background-color: #000;
  color: white;

  font-family: sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
  color: white;
}

.sub-body {
  padding: 80px 10% 1rem 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
    position: relative;
}

.section-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  flex-direction: column;
  width: 100%;

}

.section-title {
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(90deg, #fff 44.06%, #ff2f01 99.58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title span {
  padding-left: 1rem;
  background: var(
    --yeloworange,
    linear-gradient(90deg, #ffa800 44.06%, #ff2f01 99.58%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 900px) {
  .section-title { 
    font-size: 2.4rem; 
  }
  .cards { 
    justify-content: center; 
  }
}

.blogs-list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3%;
}