.dialog-parent{
    background-color:#202020;
    width: 100%!important;
    height: fit-content !important;
    color: white;
}

.dialog-sub-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 0;
    position: relative;
}

.detail-paragraph{
    width: 80%;
    text-align: justify;
    color: #8c8c8c;
}

.close-icon{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
}