.email-result{
    background-color:#202020;
    width: 30dvw !important;
    height: 40dvh !important;
    color: white;
}

.email-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}


@media(max-width:780px){
    .email-result{
        width: 80dvw !important;
    }
}
