
.navbar-section {
    /* padding: 0 10%; */
    width: 100%;
    position: fixed;
    z-index: 10; 
    background-color: rgba(3, 3, 3, 0.77);
    top: 0;
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
  backdrop-filter: blur(4px);

  }
  .navbar {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13.051px;
  }
  
  .logo-section .text {
    height: 38.5px;
  }
  
  .logo-section .logo {
    height: 63.949px;
  }
  
  .nav-links ul {
    display: flex;
    list-style: none;
    gap: 20px;
    font-weight: 600;
    line-height: normal;
  }
  #menu-icon {
    display: none;
  }
  #close-icon {
    display: none;
  }
  @media (max-width: 900px) {
    .nav-links {
      transform: translateX(100%);
      height: 100dvh;
      position: fixed;
      width: 50dvw;
      /* background-color: blue; */
      top: 0;
      right: 0;
      justify-content: flex-start;
  
      display: flex;
      padding-right: 2rem;
      padding-top: 2rem;
      flex-direction: column;
      margin: 0;
      /* background-color: #ffaa00db; */
      background-color: #000000e9;
      transition: 0.6s;
    }
  
    .nav-links ul {
      flex-direction: column;
      margin: 0;
    }
    #menu-icon {
      display: flex;
      cursor: pointer;
    }
    #close-icon {
      display: flex;
      margin-bottom: 5rem;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    } 
    .logo-section .text {
        height: 30.5px;
      }
      
      .logo-section .logo {
        height: 45.949px;
      }
      
  }
  .--display-navlinks {
    transform: translateX(0);
  }
  
  .--hide {
    display: none !important;
  }  








  
.scroll-bar{
  scale: 0 1;
  transform-origin: left;
  position: fixed;
  height: 10px;
  z-index: -1;
  width: 100%;
  animation: scroll-watcher linear;
  animation-timeline: scroll() !important;

  background: var(
    --yeloworange,
    linear-gradient(90deg, #ffaa0073 44.06%, #ff300159 99.58%)
  );
  top: 0;
}

@keyframes scroll-watcher {
  100% {
      scale: 1 1;
  }
}


 