.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.footer .email {
  text-align: center;
  /* border-left: 1px solid green;
  border-right: 1px solid green; */
  /* margin: 0 1.5rem;
  padding: 1.5rem; */
}

.footer .social-media .socials {
  display: flex;
}

.bottom-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0 0 0;
}

.bottom-footer p {
  text-align: center;
  font-size: 0.8rem;
}

.footer-logo img {
  width: 25px;
}

.underline-footer {
  background: linear-gradient(
    270deg,
    rgba(255, 174, 17, 0) 19.29%,
    rgba(206, 107, 10, 0.69) 119.6%
  );
  width: 50px;
  height: 2px;
}

.underline-footer-reverse {
  background: linear-gradient(
    -270deg,
    rgba(255, 174, 17, 0) 19.29%,
    rgba(206, 107, 10, 0.69) 119.6%
  );
  width: 50px;
  height: 2px;
}
.underline-footer-top-reverse {
  background: linear-gradient(
    -270deg,
    rgba(255, 174, 17, 0) 19.29%,
    rgba(206, 107, 10, 0.69) 119.6%
  );
  width: 150px;
  height: 2px;
}
.underline-footer-top {
  background: linear-gradient(
    270deg,
    rgba(255, 174, 17, 0) 19.29%,
    rgba(206, 107, 10, 0.69) 119.6%
  );
  width: 150px;
  height: 2px;
}
.underline-footer-vertical-reverse {
  background: linear-gradient(
    0deg,
    rgba(255, 174, 17, 0) 19.29%,
    rgba(206, 107, 10, 0.69) 119.6%
  );
  width: 2px;
  height: 50px;
  margin: 0 2rem;
}
.underline-footer-vertical {
  background: linear-gradient(
    180deg,
    rgba(255, 174, 17, 0) 19.29%,
    rgba(206, 107, 10, 0.69) 119.6%
  );
  width: 2px;
  height: 50px;
  margin: 0 2rem;
}

@media (max-width: 900px) {
  .footer {
    flex-direction: column;
    padding: 2rem 0;
  }

  .footer .email {
    text-align: center;
    border: 0px;
    /* border-top: 1px solid green;
    border-bottom: 1px solid green; */
    margin: 0 1.5rem;
    padding: 1.5rem;
  }
  .address,
  .email,
  .social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .underline-footer-vertical-reverse {
    background: linear-gradient(
      270deg,
      rgba(255, 174, 17, 0) 19.29%,
      rgba(206, 107, 10, 0.69) 119.6%
    );
    width: 150px;
    height: 2px;
    margin: 0;
  }
  .underline-footer-vertical {
    background: linear-gradient(
      -270deg,
      rgba(255, 174, 17, 0) 19.29%,
      rgba(206, 107, 10, 0.69) 119.6%
    );
    width: 150px;
    height: 2px;
    margin: 0;
  }

  .mobile-row {
    flex-direction: row !important;
    justify-content: center !important;
  }
}
.contact img {
  background: var(
    --yeloworange,
    linear-gradient(90deg, #ffa800 44.06%, #ff2f01 99.58%)
  );
  width: 20px;
  margin-right: 1rem;
}
.social-media img {
  background: var(
    --yeloworange,
    linear-gradient(90deg, #ffa800 44.06%, #ff2f01 99.58%)
  );
  width: 20px;
  margin-right: 1rem;
}

.social-media img {
  width: 20px;
  margin-right: 1rem;
}

.top-footer-logo {
  width: 40px;
}
.top-footer-logo-text {
  /* width: 80px; */
  padding: 2rem;
}

.footer-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 0 0 0;
}

.--display-center-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials {
  width: auto;
  display: flex;
  justify-content: center;
  height: 3rem;
  padding-bottom: 1rem;
}
.socials a {
  padding: 1rem;
  padding-bottom: 2rem;
  transition: 0.6s;

}
.socials a:hover {
  font-size: 2rem;
  transition: 0.6s;
}

.--display-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon {
  display: none;
}
.social-text {
  display: static;
}
@media (max-width: 780px) {
  .social-icon {
    display: inline !important;
  }
  .social-text {
    display: none;
  }
}
