.loading-widget{
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background : rgba(50, 50, 50, 0.5); */
    width: 100%;
    /* height: 100%; */
    height: 25dvh;
    color: white;
    z-index: 9999999;
}
.loading-widget-light{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background : rgba(50, 50, 50, 0.5); */
    width: 100%;
    height: 100%;
    color: black;
    z-index: 9999999;
}
.loading-widget-full{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background : rgba(50, 50, 50, 0.5);
    width: 100dvw;
    height: 100dvh;
    color: white;
    z-index: 9999999;
    top: 0px;
    left: 0px;
}
.loading-widget-light-full{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100dvw;
    height: 100dvh;
    color: black;
    z-index: 9999999;
    top: 0px;
    left: 0px;
}