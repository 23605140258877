input {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #8c8c8c;
  box-shadow: 0 0 0 transparent;

  filter: none;
  width: 100%;
  margin-bottom: 2rem;
  height: 2rem;
  color: white;
}
input:focus {
  outline: none !important;
}
input::shadow {
  outline: none !important;
}

.form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

form {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media(max-width:900px){
  form{
    width:90%
  }
  
}
.btn {
  align-self: center;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.4rem;
  background: var(
    --yeloworange,
    linear-gradient(90deg, #ffa800 44.06%, #ff2f01 99.58%)
  );
  padding: 1rem 2.5rem;
  width: fit-content;
  cursor: pointer;
  margin-top: 2rem;
  box-shadow: none !important;
  border: 0px;
  color: white !important;
}


.selector{
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #8c8c8c;
  /* border-bottom: 1px solid #8c8c8c; */
  box-shadow: 0 0 0 transparent;

  filter: none;
  width: 100%;
  margin-bottom: 2rem;
  height: 2rem;
  color: white;
}

.selector option{
  background-color: #000;
  border: 0px;

}
.selector option div{ 
  padding: 10px !important; 
  height: 200px;

}
.selector option:hover {
  background: rgb(64, 64, 64) !important; 
}
.selector:focus{  
  border: 0px;
  outline: none;
  border-bottom: 1px solid #8c8c8c;

}