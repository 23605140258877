
.get-in-touch {
    background: #202020ad;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 4rem;
    /* filter: blur(1.1px); */
  }

  